import parse from 'html-react-parser'
import config from '../config/index'
import './Offline.css'

const Offline = ({ language }) => {

  const content = config.content.offline[language]
  const description = content.description

  return (
    <main id="offline" className="offline">
      <p>
        {parse(description)}
      </p>
    </main>
  )

}

export default Offline
