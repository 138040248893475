import parse from 'html-react-parser'
import config from '../config/index'
import './ComingSoon.css'

const ComingSoon = ({ language }) => {

  const content = config.content.comingsoon[language]
  const description = content.description

  return (
    <main id="coming-soon" className="coming-soon">
      <p>
        {parse(description)}
      </p>
    </main>
  )

}

export default ComingSoon
