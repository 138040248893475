import parse from 'html-react-parser'
import config from '../config/index'
import './BlockLandscape.css'

const BlockLandscape = ({ language }) => {

  const content = config.content.landscape[language]
  console.log(language, config.content.landscape)
  const description = content.description

  return (
    <main id="block-landscape" className="block-landscape">
      <p>
        {parse(description)}
      </p>
    </main>
  )

}

export default BlockLandscape
