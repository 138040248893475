import { useEffect } from 'react'
import { isMobile, isSafari } from 'react-device-detect';

import parse from 'html-react-parser'
import config from  '../../config/index'
import './DiceResults.css'

const content = config.content
const rollCTAs = content.cta
const contentResults = content.results
const s3_url = config.content.assets.s3_url

const DiceResults = ({ language, amount, rollAgain }) => {

  useEffect(() => {
    // console.log("isMobile: " + isMobile + " isSafari: " + isSafari )
    const bottom = document.querySelector('.dice_results__bottom')
    const shortGif = document.querySelector('.short-gif')
    if (typeof bottom === 'object' && bottom !== null) {
      if ((isMobile && !isSafari ) && window.innerHeight !== document.body.clientHeight) {
        bottom.style.paddingBottom = '10vh'
        // if (!document.body.classList.contains('no-scroll')) document.body.classList.add('no-scroll')
      } else {
        bottom.style.paddingBottom = '0'
        // document.body.classList.remove('no-scroll')
      }
    }
    if ((isMobile && isSafari) && window.innerHeight > 670){
      shortGif.style.paddingBottom = '10vh';
      // console.log(window.innerHeight + ' '  +  document.body.clientHeight)
    }
  });

  const getCardTitleClass = (key) => {
    if (!contentResults.hasOwnProperty(key)) return ''
    if (!contentResults[key].hasOwnProperty(language)) return ''
    if (!contentResults[key][language].hasOwnProperty('titleClass')) return ''
    return contentResults[key][language]['titleClass']
  }

  const getCardStyle = (key, property) => {
    if (!contentResults.hasOwnProperty(key)) return 'e1'
    if (!contentResults[key].hasOwnProperty('styles')) return 'e2'
    if (!contentResults[key]['styles'].hasOwnProperty(property)) return 'e3'
    return contentResults[key]['styles'][property]
  }

  const getCardAlt = (key) => {
    if (!contentResults.hasOwnProperty(key)) return 'e1'
    if (!contentResults[key].hasOwnProperty(language)) return 'e2'
    if (!contentResults[key][language].hasOwnProperty('alt')) return 'e3'
    return contentResults[key][language]['alt']
  }

  const getCardSrc = (amount) => {
    const imageName = (amount < 2 || amount > 12) ? `card-invalid.png` : `card-${amount}.gif`
    return `${s3_url}${imageName}`
  }
  const getCardSrcShort = (amount) => {
    const imageName = (amount < 2 || amount > 12) ? `card-invalid.png` : `card-${amount}-short.gif`
    return `${s3_url}${imageName}`
  }

  const clickGive = () => {
    if (language === 'en') {
      window.open('https://danslarue.org/en/give-outplay-homelessness/', '_blank')
    } else {
      window.open('https://danslarue.org/donnez-dejouer-itinerance/', '_blank')
    }
  }

  const clickGivePortal = (e) => {
    e.target.blur()
    const body = document.body
    const footer = document.querySelector('.logo_footer')
    const modal = document.getElementById('bbox-root')
    if (modal !== null) {
      modal.style.display = 'block'
      modal.classList.add('fade-in')
      if (!footer.classList.contains('fade-out')) footer.classList.add('fade-out')
      setTimeout(function () {
        if (!body.classList.contains('overlay')) body.classList.add('overlay')
        footer.style.display = 'none'
        footer.classList.remove('fade-out')
        modal.classList.remove('fade-out')
      }, 500)
    }
  }

  const clickRollAgain = () => {
    rollAgain(0)
  }

  // get values
  const key = (amount < 2 || amount > 12) ? 'roll0' : `roll${amount}`
  const cardSrcShort = getCardSrcShort(amount)
  const cardSrc = getCardSrc(amount)
  const cardAlt = getCardAlt(key)
  // const formattedAmount = (language === 'fr') ? amount + '$' : '$' + amount
  const classNameTop = getCardStyle(key, 'top')
  // const classNameBottom = getCardStyle(key, 'bottom')
  const titleClass = getCardTitleClass(key)

  return (
    <div className='dice_results' add-role='contentinfo'>
      <div className={`dice_results__top ${classNameTop}`}>
        <div className="dice_results__top__inner">
          <h1 className={titleClass}>{parse(contentResults[key][language]['title'])}</h1>
          <p>{parse(contentResults[key][language]['description'])}</p>
          <div className="dice_results__top__inner__ctas">
            <button onClick={clickGive} className="btn-give mr25">{parse(rollCTAs[language]['give'])}</button> 
            <button onClick={clickRollAgain} className="btn-roll">{parse(rollCTAs[language]['rollAgain'])}</button>
          </div>
        </div>
      </div>
      <div className={`dice_results__bottom ${classNameTop}`}>
        <div className="dice_results__top__inner">
          <img className='short-gif' src={cardSrcShort} alt={cardAlt} />
          <img className='standard-gif' src={cardSrc} alt={cardAlt} />
        </div>
      </div>
    </div>
  )

}

export default DiceResults
