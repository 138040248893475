
const metaUtils = {

  /*
  ---------------------------------------------------------------
  MEMBERS
  ---------------------------------------------------------------
  */

  lang: '',
  content: {},

  errors: [],

  /*
  ---------------------------------------------------------------
  INTERFACE
  ---------------------------------------------------------------
  */

  /**
   * @param {object} content            object containing 1...n collections, using 2-character language key 
   *      {object}  <language>          required object key corresponding to language context (eg, 'en')
   *          {string}  language            required two-character string for HTML document language (eg, 'en')
   *          {string}  title               required string for HTML page title
   *          {string}  description         required string for meta description
   * @param {string} language           two-character string for current language contex (eg, 'en')
   * @return string
   */
  updateMeta(content, language = '') {
    // require valid language
    if (this.isMalformedLanguage(language)) return false
    // require content available for specified language
    if (!this.hasValidContentCollection(content, language)) return false
    if (!this.hasValidContent(content[language])) return false
    // update DOM if properties have changed
    const data = content[language]
    const htmlLang = data.language
    const htmlTitle = data.title
    const metaDescription = data.description
    if (document.documentElement.lang !== htmlLang) {
      // console.log('change document language')
      document.documentElement.setAttribute("lang", htmlLang);
    }
    if (document.title !== htmlTitle) {
      // console.log('change page title')
      document.title = htmlTitle
    }
    if (document.head.querySelector('meta[name="description"]').content !== metaDescription) {
      //console.log('change meta description')
      document.head.querySelector('meta[name="description"]').content = metaDescription
    }
    return true
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: CONTENT
  ---------------------------------------------------------------
  */

  hasValidContentCollection(content, language) {
    // require object
    if (typeof content !== 'object') {
      console.log('metaUtilsUtils:', 'content object required')
      return false
    }
    // require language object
    if (!content.hasOwnProperty(language)) {
      console.log('metaUtilsUtils:', 'language not specified')
      return false
    }
    return true
  },

  hasValidContent(content, language) {
    // set language content
    // require 2 character string: HTML language
    if (!content.hasOwnProperty('language')) {
      console.log('metaUtilsUtils:', 'language not specified')
      return false
    }
    if (typeof content.language !== 'string' || content.language.length !== 2) {
      console.log('metaUtilsUtils:', 'language must be a 2-character string')
      return false
    }
    // require string: HTML title
    if (!content.hasOwnProperty('title')) {
      console.log('metaUtilsUtils:', 'title not specified')
      return false
    }
    if (typeof content.title !== 'string' || content.title === '') {
      console.log('metaUtilsUtils:', 'title must be a non-empty string')
      return false
    }
    // require string: meta description
    if (!content.hasOwnProperty('description')) {
      console.log('metaUtilsUtils:', 'description not specified')
      return false
    }
    if (typeof content.description !== 'string' || content.description === '') {
      console.log('metaUtilsUtils:', 'description must be a non-empty string')
      return false
    }
    return true
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: LANGUAGE
  ---------------------------------------------------------------
  */

  isMalformedLanguage(language) {
    // verifies that language is 2-character string only
    if (typeof language === 'string' || language.trim().length === 2) return false
    return true
  }

}

export default metaUtils
