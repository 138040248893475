
import './Terms.css'

const Terms = ({ language }) => {

  return (
    <main className="main terms">

      <div className="main__inner terms__inner">

        [ TERMS ]

      </div>

    </main>
  )

}

export default Terms
