import { useEffect, useRef } from 'react'
import DiceBox from '@3d-dice/dice-box'

import './DiceCanvas.css'

const DiceCanvas = ({ diceKey, language, shakeScore, updateTotal }) => {

  /*
  ----------------------------------------------------
  helpers
  ----------------------------------------------------
  */

  const getRandomDelay = (min = 10, max = 99) => {
    const delay = Math.floor(Math.random() * 100)
    if (delay < min) return (delay * delay * 3)
    return delay * 3
  }

  const getRandomMax1 = (min = .1, max = .99) => {
    const result = parseFloat(Math.random().toFixed(1))
    if (result < min) return min
    if (result > max) return max/2
    return result
  }

  const getRandomMax10 = (min = 3, max = 10) => {
    const result = parseInt(Math.random()*10)
    if (result < min) return min * 3
    if (result > max) return result - 3
    return result
  }

  const getRollParams = () => {

    // default values
    const params = {
      delay: getRandomDelay(),
      gravity: 1,
      mass: getRandomMax1(.6),
      friction: .6,
      restitution: 0,
      angularDamping: getRandomMax1(),
      linearDamping: getRandomMax1(),
      spinForce: getRandomMax10(),
      throwForce: getRandomMax10(),
      startingHeight: 8
    }
    return params
  }

  /*
  ----------------------------------------------------
  useRef
  ----------------------------------------------------
  */

  const hasRolled = useRef(false)
  const isRolling = useRef(false)

  /*
  ----------------------------------------------------
  useEffect
  ----------------------------------------------------
  */

  useEffect(() => {

    // mobile height hack
    const inner = document.querySelector('.dice_canvas__inner')
    const footer = document.querySelector('.logo_footer')
    if (typeof inner === 'object' && inner !== null && typeof footer === 'object' && footer !== null) {
      if (window.innerHeight !== document.body.clientHeight) {
        const footerHeight = footer.clientHeight
        inner.style.height = (window.innerHeight - footerHeight) + 'px'
        // if (!document.body.classList.contains('no-scroll')) document.body.classList.add('no-scroll')
      } else {
        // document.body.classList.remove('no-scroll')
      }
    }

    // 3d dice
    if (!hasRolled.current) {

      hasRolled.current = true

      const params = getRollParams()

      const diceBox = new DiceBox("#dicebox", {
        scale: 9,
        delay: params.delay,
        gravity: params.gravity,
        mass: params.mass,
        throwForce: params.throwForce,
        spinForce: params.spinForce,
        friction: params.friction,
        restitution: params.restitution,
        angularDamping: params.angularDamping,
        linearDamping: params.linearDamping,
        offscreen: false,
        enableShadows: true,
        theme: 'danslarue',
        themeColor: '#ffffff',
        assetPath: '/assets/dice-box/',
        onRollComplete: setNewAmount
      })

      diceBox.init().then(() => {
        isRolling.current = true
        diceBox.roll('2d6')
      })

    }

  })

  /*
  ----------------------------------------------------
  callbacks
  ----------------------------------------------------
  */

  const setNewAmount = (e) => {
    setTimeout(() => {
      // check for querystring override
      const qs = new URLSearchParams(window.location.search)
      const qsTotal = parseInt(qs.get('total'))
      if (qsTotal > 1 && qsTotal < 13) {
      // override total
        updateTotal(qsTotal)
      } else {
        // default
        isRolling.current = false
        updateTotal(e[0].value)
      }
    }, 500)
  }

  /*
  ----------------------------------------------------
  view
  ----------------------------------------------------
  */

  return (
    <div className='dice_canvas bg-yellow' add-role='contentinfo'>
      <div className='dice_canvas__inner'>
        <div id="dicebox" />
      </div>
    </div>
  )

}

export default DiceCanvas
