import { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { isMobile, isTablet } from 'react-device-detect'
import useScreenOrientation from 'use-screen-orientation'

// utils
import browserUtils from './utils/browserUtils'
import config from './config/index'
import metaUtils from './utils/metaUtils'
import pageUtils from './utils/pageUtils'
// import securityUtils from './utils/securityUtils'

// pages: intercepts
import BlockDesktop from './pages/BlockDesktop'
import BlockLandscape from './pages/BlockLandscape'
import ComingSoon from './pages/ComingSoon'
import Offline from './pages/Offline'

// pages: live site
import Dice from './pages/Dice'
import Terms from './pages/Terms'

// common page components
import Footer from './components/Footer'

// env setup
const envConfig = config.getEnvConfig()
pageUtils.init(envConfig.frontend)

// generate a new bypass hash
// const hash = securityUtils.createHash('')

function App() {

  /*
  -----------------------------------------------------------------
  setup
  -----------------------------------------------------------------
  */

  // runtime setup
  if (envConfig.frontend.disableConsole) browserUtils.disableConsole()

  /*
  -----------------------------------------------------------------
  useState + related accessor
  -----------------------------------------------------------------
  */

  // useRef
  const language = useRef(config.lang)

  // useState
  // const [language, setLanguage] = useState(config.lang)
  const [viewKey, setViewKey] = useState(1000000)
  const [orientation, angle] = useScreenOrientation()

  // increment component keys to trigger redraw (via remounting of component)
  const incrementKeys = () => {
    setViewKey(viewKey + 1)
  }

  /*
  -----------------------------------------------------------------
  useEffect
  -----------------------------------------------------------------
  */

  useEffect(() => {

    // update HEAD properties if required
    metaUtils.updateMeta(config.content.meta, language.current)

    // toggle overflow lock
    //const body = document.querySelector('body')
    //body.classList.add('mobile-nav-expanded')

  })

  /*
  -----------------------------------------------------------------
  callbacks
  -----------------------------------------------------------------
  */

  const onLogoClick = () => {
    incrementKeys()
  }

  /*
  -----------------------------------------------------------------
  views
  -----------------------------------------------------------------
  */

  // show: offline (eg, maintenance mode)
  if (pageUtils.showOffline()) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<Offline language={language.current} />} />
          </Routes>
        </Router>
      </>
    )
  }

  // show: coming soon
  if (pageUtils.showComingSoon()) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<ComingSoon language={language.current} />} />
          </Routes>
        </Router>
      </>
    )
  }

  // block: desktop
  if (pageUtils.blockDesktop() && !isMobile) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<BlockDesktop language={language.current} />} />
          </Routes>
        </Router>
      </>
    )
  }


  // block: landscape (on mobile)
  if (isMobile || isTablet) {
    if (orientation !== 'portrait' || angle !== 0) {
      return (
        <>
          <Router>
            <Routes>
              <Route path='*' element={<BlockLandscape language={language.current} />} />
            </Routes>
          </Router>
        </>
      )
    }
  }

  // live UX
  return (
    <>
      <Router>

        <Routes>

          <Route path='/fr/terms' element={<Terms language={language.current} />} />
          <Route path='/fr/*' element={<Dice language={language.current} />} />

          <Route path='/en/terms' element={<Terms language={language.current} />} />
          <Route path='/en/*' element={<Dice language={language.current} />} />

          <Route path='/*' element={<Dice key={viewKey} language={language.current} />} />

        </Routes>

        <Footer language={language.current} logoClick={onLogoClick} />

      </Router>
    </>
  )

}

export default App
