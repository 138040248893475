
const browserUtils = {

  /*
  ---------------------------------------------------------------
  PROPERTIES
  ---------------------------------------------------------------
  */

  pages: {
    count: 0
  },

  /*
  ---------------------------------------------------------------
  COOKIES
  ---------------------------------------------------------------
  */

  getCookie(cookieName) {
    let name = cookieName + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        const cookieValue = c.substring(name.length, c.length)
        // console.log('getCookie()', cookieName, cookieValue)
        return cookieValue
      }
    }
    return ''
  },

  setCookie(cookieName, cookieValue, expiryInDays) {
    const d = new Date()
    d.setTime(d.getTime() + (expiryInDays * 24 * 60 * 60 * 1000))
    let expires = "expires=" + d.toUTCString()
    // console.log('setCookie()', cookieName, cookieValue)
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"
  },

  /*
  ---------------------------------------------------------------
  LOCATION: DOMAIN
  ---------------------------------------------------------------
  */

  getDomain() {
    const hostname = window.location.host.split(':')
    return hostname[0]
  },

  /*
  ---------------------------------------------------------------
  LOCATION: URL
  ---------------------------------------------------------------
  */

  // for browser history
  getPageById(id = 0) {
    id = parseInt(id)
    if (id === 0) return false
    const pageKey = `page_${id}`
    if (!this.pages.hasOwnProperty(pageKey)) return false
    return this.pages[pageKey]
  },

  // for browser history (uses count as simple id)
  newPage(page) {
    this.pages.count++
    const id = this.pages.count
    const pageKey = `page_${id}`
    this.pages[pageKey] = page
    return id
  },

  updateUrlAndTitle(url = '', title = '') {
    if (!window.history || !window.history.pushState) return
    const pageUrl = (url !== '') ? url : '/'
    const pageTitle = (title !== '') ? title : document.title
    const page = {
      url: pageUrl,
      title: pageTitle
    }
    const pageId = this.newPage(page)
    window.history.pushState({ 'id': pageId }, pageTitle, pageUrl);
  },

  /*
  ---------------------------------------------------------------
  DARK MODE
  ---------------------------------------------------------------
  */

  isDarkMode() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) return true
    return false
  },

  /*
  ---------------------------------------------------------------
  DEBOUNCING
  ---------------------------------------------------------------
  */

  // debounce handler to throttle React callbacks
  debounce(fn, ms) {
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  },

  /*
  ---------------------------------------------------------------
  DIMENSIONS and VIEW STATE
  ---------------------------------------------------------------
  */

  getDimensionsObject() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  },

  /*
  ---------------------------------------------------------------
  DISABLE CONSOLE LOGGING
  ---------------------------------------------------------------
  */

  disableConsole() {
    console.error = function no_console() { }
    console.group = function no_console() { }
    console.groupEnd = function no_console() { }
    console.info = function no_console() { }
    console.log = function no_console() { }
    console.warn = function no_console() { }
  },

}

export default browserUtils;
