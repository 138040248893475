import { useEffect, useRef, useState } from 'react'

import DiceCanvas from '../components/Dice/DiceCanvas'
import DiceResults from '../components/Dice/DiceResults'
import DiceSplash from '../components/Dice/DiceSplash'

import browserUtils from '../utils/browserUtils'

import './Dice.css'

// override shake score via querystring
const querystring = new URLSearchParams(window.location.search)
const queryStringForce = querystring.get('force')
const forceShakeScore = queryStringForce !== null ? queryStringForce : 0

const Dice = ({ language }) => {

  /*
  -----------------------------------------------------------------
  helpers
  -----------------------------------------------------------------
  */

  // increment component keys to trigger redraw (via remounting of component)
  const incrementKeys = () => {
    setDiceKey(diceKey + 1)
  }

  function getRandomShakeScore() {
    return Math.floor(Math.random() * 100)
  }

  // function setShakeScore(newScore) {
  //   if (parseInt(newScore) < 1) return
  //   shakeScore.current = newScore
  // }

  /*
  --------------------------------------------
  useRef
  --------------------------------------------
  */

  const lastDimensions = useRef(browserUtils.getDimensionsObject())
  const shakeScore = useRef(getRandomShakeScore())

  if (forceShakeScore > 0 ) {
    if (forceShakeScore > 100) {
      console.log('Force: specify a value between 1-99')
    } else {
      shakeScore.current = forceShakeScore
    }
  }

  /*
  --------------------------------------------
  useState
  --------------------------------------------
  */

  const [showSplash, setShowSplash] = useState(true)
  const [amount, setAmount] = useState(0)
  const [diceKey, setDiceKey] = useState(1000000)

  /*
  --------------------------------------------
  useEffect
  --------------------------------------------
  */

  useEffect(() => {

    // disable touchStart
    window.addEventListener('touchstart', (e) => {
      e.preventDefault();
    });

    // window orientation change handler: Safari
    try {
      window.addEventListener("orientationchange", function () {
        incrementKeys()
      }, false);
    } catch (e) {
      // ignore
    }

    // window resize handler
    const debouncedHandleResize = browserUtils.debounce(function handleResize() {
      const diffWidth = Math.abs(lastDimensions.current.width - window.innerWidth)
      const diffHeight = Math.abs(lastDimensions.current.height - window.innerHeight)
      if (diffWidth > 5 || diffHeight > 5) {
        lastDimensions.current = browserUtils.getDimensionsObject()
        incrementKeys()
      }
    }, 5);

    window.addEventListener('resize', debouncedHandleResize);
    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    }

  });

  /*
  --------------------------------------------
  CALLBACKS
  --------------------------------------------
  */

  const rollAgain = () => {
    shakeScore.current = getRandomShakeScore()
    updateTotal(0)
  }

  const updateTotal = (total) => {
    setAmount(total)
  }

  const begin = () => {
    setShowSplash(false)
  }

  /*
  --------------------------------------------
  CALLBACKS
  --------------------------------------------
  */

  if (showSplash) {
    return (
      <main className="dice">
        <div className="dice__inner">
          <DiceSplash key={diceKey} diceKey={diceKey} language={language} begin={begin} />
        </div>
      </main>
    )
  }

  if (amount > 1) {
    return (
      <main className="dice">
        <div className="dice__inner">
          <DiceResults key={diceKey} diceKey={diceKey} language={language} amount={amount} rollAgain={rollAgain} />
        </div>
      </main>
    )
  }

  return (
    <main className="dice">
      <div className="dice__inner">
        <DiceCanvas key={diceKey} diceKey={diceKey} language={language} shakeScore={shakeScore.current} updateTotal={updateTotal} />
      </div>
    </main>
  )

}

export default Dice
