import browserUtils from './browserUtils'

const languageUtils = {

  /*
  ---------------------------------------------------------------
  MEMBERS
  ---------------------------------------------------------------
  */

  lang: '',

  availableLanguages: [],
  defaultLang: '',

  detection: '',
  domainLang: '',

  useCookie: false,
  cookieName: 'lang',

  /*
  ---------------------------------------------------------------
  INTERFACE
  ---------------------------------------------------------------
  */

  /**
   * @param {object} settings
   *      {array}   availableLanguages  required array of two-character languages, eg ['en', 'fr']
   *      {string}  defaultLanguage     required two-character string, eg 'en'
   *      {boolean} detection           required string, permitted values: 'domain', 'user-agent'
   *      {string}  domainLang          required if detection === 'domain'
   *      {boolean} useCookie           required
   *      {string}  cookieName          required if useCookie === true
   * @param {string} fallback
   *    Optional two-character string used to specify a language, eg 'en'
   * @return string
   */
  getLang(settings, fallback = '') {
    // require valid settings
    if (!this.hasValidSettings(settings)) return fallback
    // setup
    this.initSettings(settings)
    // determine language
    this.setLang()
    // return
    if (this.lang.length === 0) return fallback
    return this.lang
  },

  /*
  ---------------------------------------------------------------
  INIT
  ---------------------------------------------------------------
  */

  // only call after {settings} object has been validated
  initSettings(settings) {
    this.availableLanguages = settings.availableLanguages
    this.defaultLang = settings.defaultLang
    this.detection = settings.detection
    if (settings.useCookie) {
      this.useCookie = settings.useCookie
      if (settings.cookieName !== '') this.cookieName = settings.cookieName
    }
  },

  /*
  ---------------------------------------------------------------
  CONTROLLER
  ---------------------------------------------------------------
  */

  setLang() {
    // language detection methods (DO NOT MODIFY ORDER)
    if (this.setLanguageByQuerystring()) return this.saveLang()
    if (this.setLanguageByDomain()) return this.saveLang()
    if (this.setLanguageByCookie()) return this.saveLang()
    if (this.setLanguageByUserAgent()) return this.saveLang()
    // fallback: default language
    this.lang = this.defaultLang
    return this.saveLang()
  },

  /*
  ---------------------------------------------------------------
  COOKIES
  ---------------------------------------------------------------
  */

  // write cookie and return language
  saveLang() {
    if (this.useCookie) {
      // console.log('Write lang to cookie')
      // console.log(this.useCookie, this.cookieName, this.lang)
      browserUtils.setCookie(this.cookieName, this.lang)
    }
    return this.lang
  },

  /*
  ---------------------------------------------------------------
  LANGUAGE DETECTION: COOKIE
  ---------------------------------------------------------------
  */

  setLanguageByCookie() {
    if (this.useCookie) {
      const cookieLang = browserUtils.getCookie(this.cookieName)
      if (typeof cookieLang === 'string' && cookieLang !== '') {
        // console.log('Set language by cookie')
        this.lang = cookieLang
        return true
      }
    }
    return false
  },

  /*
  ---------------------------------------------------------------
  LANGUAGE DETECTION: DOMAIN
  ---------------------------------------------------------------
  */

  setLanguageByDomain() {
    if (this.detection === 'domain') {
      if (typeof this.domainLang === 'string' && this.domainLang !== '') {
        // console.log('Set language by domain')
        this.lang = this.domainLang
        return true
      }
    }
    return false
  },

  /*
  ---------------------------------------------------------------
  LANGUAGE DETECTION: QUERYSTRING
  ---------------------------------------------------------------
  */

  setLanguageByQuerystring() {
    // TODO: refactor to allow configuration of querystring parameter name
    const qsLang = this.getLanguageFromQuerystring('lang')
    if (qsLang.length > 0 && this.getVerifiedLang(qsLang)) {
      // console.log('Set language by querystring')
      this.lang = qsLang
      return true
    }
    return false
  },

  getLanguageFromQuerystring(parameterName) {
    if (typeof parameterName !== 'string') return null
    const querystring = new URLSearchParams(window.location.search)
    const parameterValue = this.getSanitizedLang(querystring.get(parameterName))
    return parameterValue
  },

  /*
  ---------------------------------------------------------------
  LANGUAGE DETECTION: USER AGENT (browser)
  ---------------------------------------------------------------
  */

  setLanguageByUserAgent() {
    if (this.detection === 'user-agent') {
      const defaultUserAgentLang = this.getDefaultUserAgentLanguages()
      if (defaultUserAgentLang.length > 0 && this.availableLanguages.includes(defaultUserAgentLang)) {
        // console.log('Set language by user-agent')
        this.lang = defaultUserAgentLang
        return true
      }
    }
    return false
  },

  getDefaultUserAgentLanguages(fallback = '') {
    let languages = languageUtils.getUserAgentLanguages()
    if (typeof languages === 'string') {
      languages = languages.toLowerCase()
      if (languages.indexOf('fr') > -1) {
        // console.log('userAgent', 'fr')
        return 'fr'
      }
      if (languages.indexOf('en') > -1) {
        // console.log('userAgent', 'en')
        return 'en'
      }
    }
    // console.log('userAgent', fallback)
    return fallback
  },

  getUserAgentLanguages() {
    return navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: LANGUAGE
  ---------------------------------------------------------------
  */

  getSanitizedLang(lang) {
    if (typeof lang !== 'string' || lang.trim().length === 0) return ''
    return lang.toLowerCase().trim()
  },

  getVerifiedLang(lang, fallback = '') {
    lang = this.getSanitizedLang(lang)
    if (lang.length > 0) {
      if (this.isValidLanguage(lang)) return lang
    }
    fallback = this.getSanitizedLang(fallback)
    if (fallback.length > 0) return fallback
    return ''
  },

  isValidLanguage(lang) {
    lang = this.getSanitizedLang(lang)
    if (lang.length > 0 && this.availableLanguages.includes(lang)) return true
    return false
  },

  /*
  ---------------------------------------------------------------
  VALIDATION: SETTINGS
  ---------------------------------------------------------------
  */

  hasValidSettings(settings) {
    // require object
    if (typeof settings !== 'object') {
      console.log('languageUtils:', 'settings object required')
      return false
    }
    // require non-empty array of available languages
    if (!settings.hasOwnProperty('availableLanguages')) {
      console.log('languageUtils:', 'availableLanguages not specified')
      return false
    }
    if (!Array.isArray(settings.availableLanguages) || settings.availableLanguages.length === 0) {
      console.log('languageUtils:', 'availableLanguages must be a non-empty array')
      return false
    }
    // require default language string
    if (!settings.hasOwnProperty('defaultLang')) {
      console.log('languageUtils:', 'defaultLang not specified')
      return false
    }
    if (typeof settings.defaultLang !== 'string' || settings.defaultLang === '') {
      console.log('languageUtils:', 'defaultLang must be a non-empty string')
      return false
    }
    // require: boolean 'useCookie' flag
    if (!settings.hasOwnProperty('useCookie')) {
      console.log('languageUtils:', 'useCookie not specified')
      return false
    }
    if (typeof settings.hasOwnProperty('useCookie') !== 'boolean') {
      console.log('languageUtils:', 'useCookie must be a boolean')
      return false
    }
    // conditionally require: non-empty string for cookie name
    if (settings.useCookie === true) {
      if (!settings.hasOwnProperty('cookieName')) {
        console.log('languageUtils:', 'cookieName not specified')
        return false
      }
      if (typeof settings.cookieName !== 'string' || settings.cookieName === '') {
        console.log('languageUtils:', 'cookieName must be a non-empty string')
        return false
      }
    }
    return true
  },

}

export default languageUtils;
