import securityUtils from './securityUtils'

const pageUtils = {

  config: {},
  isBypassed: false,

  init(config, bypassHash) {
    this.config = config
    const qs = new URLSearchParams(window.location.search)
    const qsBypass = qs.get('bypass')
    const qsBypassHash = qsBypass !== null ? securityUtils.getHash(qs.get('bypass')).toString() : ''
    this.isBypassed = (qsBypassHash === config.bypassHash) ? true : false
  },

  blockDesktop() {
    if (this.config.blockDesktop && !this.isBypassed) return true
    return false
  },

  showOffline() {
    if (this.config.offline && !this.isBypassed) return true
    return false
  },

  showComingSoon() {
    if (this.config.comingSoon && !this.isBypassed) return true
    return false
  }

}

export default pageUtils
