import { useEffect } from 'react'
import parse from 'html-react-parser'
import config from  '../../config/index'
import './DiceSplash.css'
import srcTop from '../../assets/images/splash-dice-top.png'
import srcBottom from '../../assets/images/splash-dice-bottom.png'

const s3_url = config.content.assets.s3_url

const DiceSplash = ({ language, begin }) => {

  const content = config.content.splash[language]

  const title = content.title
  const description = content.description
  const cta = content.cta
  const alt1 = content.alt1
  const alt2 = content.alt2
  const toggleText = content.toggleText;
  const toggleLink = content.toggleLink;

  useEffect(() => {

    // mobile height hack
    const inner = document.querySelector('.dice_splash__inner')
    if (typeof inner === 'object' && inner !== null) {
      if (window.innerHeight !== document.body.clientHeight) {
        inner.style.marginTop = '-70px'
        // if (!document.body.classList.contains('no-scroll')) document.body.classList.add('no-scroll')
      } else {
        // document.body.classList.remove('no-scroll')
      }
    }

    // preload GIFs
    if (document.body.clientHeight <= 780) {
      // on shorter mobile devices, preload short GIFs first, then regular GIFs
      preloadGIFs('-short')
      preloadGIFs('')
    } else {
      // on taller mobile devices, preload regular GIFs first, then short GIFs
      preloadGIFs('')
      preloadGIFs('-short')
    }

  });

  // image preloader
  const preloadGIFs = (suffix) => {
    for (let i=2; i<13; i++) {
      const imageName = `card-${i}${suffix}.gif`
      const img = new Image()
      img.src = `${s3_url}${imageName}`
    }
  }

  // callback
  const clickHandler = () => {
    begin()
  }

  return (
    <div className='dice_splash' add-role='contentinfo'>
      <a className='lang-toggle' href={toggleLink} >{toggleText}</a>
      <div className="dice_splash__inner">
      <div className="dice-top">
        <img src={srcTop} alt={alt1} />
      </div>
        <h1>
          {parse(title)}
        </h1>
        <p>
          {parse(description)}
        </p>
        <button className="btn btn-cta btn-border-black" onClick={clickHandler}>{parse(cta)}</button>
        <div className="dice-bottom" >
          <img src={srcBottom} alt={alt2} />

        </div>
      </div>
    </div>
  )

}

export default DiceSplash
