
import './Footer.css'

const Footer = ({ language, logoClick }) => {

  const logoAlt = (language === 'fr') ? 'Dans La Rue' : 'Dans La Rue'
  // const logoUrl = (language === 'fr') ? 'https://danslarue.org/' : 'https://danslarue.org/en/'

  const onLogoClick = () => {
    logoClick()
  }

  return (
    <footer className="logo_footer bg-black">
      <a onClick={onLogoClick} target="_blank"><img src="/assets/images/logo.png" alt={logoAlt} /></a>
    </footer>
  )

}

export default Footer
